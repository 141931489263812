<template>
    <div id="app" ref="app">
        <nav id="home">
            <ul>
                <li class="logo">
                    <a href="#">
                        <img :src="cdn + 'assets/imgs/Bekko-logo.png'" alt="Bekko" />
                    </a>
                </li>
                <li :class="{active: active=='home'}" @click="active='home'">
                    <a href="#banner">首頁</a>
                </li>
                <li :class="{active: active=='role'}" @click="active='role'">
                    <a href="#role">武將介紹</a>
                </li>
                <li :class="{active: active=='introduce'}" @click="active='introduce'">
                    <a href="#introduce">遊戲介紹</a>
                </li>
                <li class="fb">
                    <a href="https://www.facebook.com/ThreeKingdomsOldSchool">
                        <img :src="cdn + 'assets/imgs/Facebook_Logo.png'" alt="Facebook" />
                    </a>
                    <a href="https://mycard.bekko.com/thirdpay/mycard/hsg/">
                        <img :src="cdn + 'assets/imgs/mycard_80_80.png'" alt="mycard" />
                    </a>
                </li>
            </ul>
        </nav>
        <section id="banner">
            <div class="download">
                <a href="https://apps.apple.com/TW/app/id1642884878">
                    <img :src="cdn + 'assets/imgs/IOS-EN-b.png'" alt="IOS" />
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.jdgames.tysg.googleplay">
                    <img :src="cdn + 'assets/imgs/Google-EN.png'" alt="Android" />
                </a>
            </div>
        </section>
        <section id="role">
            <div class="title">
                <img :src="cdn + 'assets/imgs/title1.png'" alt="武將介紹" />
            </div>

            <div class="role-wrap">
                <div class="role-nav">
                    <div class="role-button-prev"></div>
                    <swiper ref="swiper1" :options="options1">
                        <swiper-slide v-for="(item, index) in rolenavlist" :key="'role_'+index">
                            <img :src="cdn+'assets/imgs/'+item+'-dot.png'" />
                        </swiper-slide>
                    </swiper>
                    <div class="role-button-next"></div>
                </div>
                <div class="role-detail">
                    <swiper ref="swiper2" :options="options2">
                        <swiper-slide v-for="(item, index) in rolelist" :key="'nav_'+index">
                            <img :src="cdn+'assets/imgs/'+item+'.png'" />
                        </swiper-slide>
                    </swiper>
                </div>
            </div>
        </section>
        <section id="introduce">
            <div class="title">
                <img :src="cdn + 'assets/imgs/title2.png'" />
            </div>

            <div class="introduce-wrap">
                <div class="introduce-button-prev">
                    <img :src="cdn + 'assets/imgs/nav-left.png'" />
                </div>
                <swiper ref="swiper3" :options="options3">
                    <swiper-slide v-for="(n, index) in 5" :key="index+'_'">
                        <img :src="cdn+'assets/imgs/carousel'+n+'.jpg'" />
                    </swiper-slide>
                </swiper>
                <div class="introduce-button-next">
                    <img :src="cdn + 'assets/imgs/nav-right.png'" />
                </div>
            </div>
            <div class="swiper-pagination"></div>
        </section>
        <footer>
            <p class="logo">
                <img :src="cdn+'assets/imgs/Bekko-logo.png'" />
                <img :src="cdn+'assets/imgs/icon-tw_rating-12years.png'" />
            </p>
            <p>
                <a href="https://www.bekko.com/index.html#/policy" target="_blank">隱私權政策</a> |
                <a href="https://www.bekko.com/index.html#/terms" target="_blank">用戶服務條款</a> |
                <a href="mailto:support@bekko.com">客服信箱</a>
            </p>
            <p>本遊戲涉及部分暴力內容，依遊戲軟體分級管理辦法分類為輔12級。</p>
            <p>本遊戲為免費使用，遊戲內另提供購買虛擬遊戲幣、物品等付費服務。請注意使用時間，避免沉迷。</p>
            <p>本遊戲由香港商阿爾發遊戲有限公司台灣分公司代理港台營運</p>
            <p>Copyright © 2022 Bekko Games</p>
        </footer>

        <div class="back-top" @click="goTop" ref="backtop">
            <svg
                t="1664175261579"
                class="icon"
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                p-id="2699"
                width="50"
                height="50"
            >
                <path
                    d="M511.58528 470.08768v0.04608zM909.69088 609.0752c0-105.49248-41.16992-204.57472-113.34144-278.59456-8.61184-8.81152-24.4224-14.14656-33.2288-5.53984-8.81664 8.6016-7.42912 21.61152 1.17248 30.42304 64.11264 65.75616 104.82176 149.05856 104.82176 251.78112s-40.16128 185.93792-104.82176 251.78112c-8.64768 8.81152-9.40032 22.53312-0.62464 31.1808 8.76544 8.64256 23.03488 7.3472 31.68256-1.41824 72.8064-74.11712 114.29888-173.60896 114.29888-279.60832h0.04096zM230.26176 324.85376C155.68896 399.21664 111.58528 501.6064 111.58528 609.03424c0 109.23008 44.14976 211.49696 120.89856 286.15168a22.28736 22.28736 0 0 0 31.55968-0.46592 22.28736 22.28736 0 0 0-0.4608-31.55456c-68.18816-66.304-107.38688-157.07136-107.38688-254.1312 0-95.46752 37.93408-187.6224 104.15104-253.71648 8.73472-8.68352 8.68864-21.77536 0-30.50496-13.3376-13.3888-30.12608 0-30.12608 0l0.04096 0.04096zM512.42496 242.89792c-36.0192 0-65.2544 29.2352-65.2544 65.2544s29.2352 65.2544 65.2544 65.2544 65.2544-29.2352 65.2544-65.2544-29.2352-65.2544-65.2544-65.2544z m0 99.79392c-19.04128 0-34.5344-15.49312-34.5344-34.5344s15.49312-34.5344 34.5344-34.5344c19.0464 0 34.5344 15.49312 34.5344 34.5344s-15.488 34.5344-34.5344 34.5344zM497.68448 743.74144h26.86976c4.56704-43.93984 7.84384-88.08448 9.35936-118.30272 6.83008-135.46496-19.3792-138.96704-23.01952-138.76736-3.67104-0.2048-29.88544 3.1488-22.8096 138.69056 1.58208 30.22848 4.94592 74.40896 9.6 118.37952zM478.72 892.96896a17.06496 17.06496 0 1 1-34.12992 0v-119.41888a17.06496 17.06496 0 1 1 34.12992 0v119.41888zM528.02048 956.58496a17.06496 17.06496 0 1 1-34.12992 0v-183.03488a17.06496 17.06496 0 0 1 34.12992 0v183.03488zM581.94432 849.07008a17.06496 17.06496 0 1 1-34.12992 0v-75.52a17.06496 17.06496 0 1 1 34.12992 0v75.52zM511.54432 439.46496v0.26624-0.26624z"
                    p-id="2700"
                    fill="#C59A5D"
                />
                <path
                    d="M670.848 417.10592c2.49344-3.15904 50.11456-198.07744-158.55104-392.20736v0.08192C303.78496 218.88 353.80224 414.65856 353.80224 414.65856c-163.9936 158.40768-45.952 329.04192-45.952 329.04192 28.83072-104.6272 95.46752-136.55552 95.46752-136.55552 12.42112 48.08704 60.09344 136.50432 60.09344 136.50432l30.12608 0.0512c-11.12064-45.99808-17.96096-159.88736-17.96096-159.88736-6.29248-105.53856 36.00384-113.72544 36.00384-113.72544l-0.00512-2.72384v2.67776s42.3424 8.31488 36.25984 113.80736c0 0-6.58944 113.8944-17.59232 159.8464l30.13632 0.04096s44.94336-88.58624 57.1904-136.64256c0 0 73.14944 32.2304 102.02112 131.13856 0.01024 0.01024 112.7936-170.47552-48.7424-321.1264z m44.3392 211.76832c-0.3584 2.74432-0.76288 5.44768-1.20832 8.10496-36.59264-47.75936-77.37344-66.25792-79.88224-67.36384l-44.26752-19.50208-0.65536 2.5856c-3.80416-104.20224-57.9328-120.53504-69.69856-122.84416l-15.67744 0.02048c-12.1344 2.34496-69.248 19.46112-69.79584 132.29568l-3.48672-13.48608-44.9024 21.51424c-2.31424 1.11104-40.15616 19.79392-74.66496 69.65248-9.14432-53.08928-1.39776-125.50656 71.30624-195.7376l17.12128-16.5376-5.89312-23.06048c-1.5616-6.49216-35.85536-158.58688 118.85056-321.9712 140.93824 148.79744 125.85472 287.69792 120.87296 316.1344l-17.88416 22.65088 27.59168 25.728c56.77056 52.95104 81.08544 114.11968 72.27392 181.81632z"
                    p-id="2701"
                    fill="#C59A5D"
                />
            </svg>
        </div>
    </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

export default {
    name: "App",
    components: {
        Swiper,
        SwiperSlide,
    },
    data() {
        return {
            cdn: process.env.VUE_APP_CDNPREFIX,
            isMobile: window.innerWidth <= 768,
            active: "home", // role introduce
            rolenavlist: ["role-zr", "role-gy", "role-gj", "role-smy"],
            options1: {
                loop: true,
                direction: "vertical",
                slidesPerView: 3,
                loopedSlides: 5,
                centeredSlides: true,
                navigation: {
                    nextEl: ".role-button-next",
                    prevEl: ".role-button-prev",
                },
                on: {
                    resize: function () {
                        this.updateSize();
                    },
                },
            },
            options2: {
                loop: true,
                direction: "vertical",
                loopedSlides: 5,
                autoHeight: true,
                on: {
                    resize: function () {
                        this.updateSize();
                    },
                },
            },
            adlist: [],
            options3: {
                loop: true,
                pagination: {
                    el: ".swiper-pagination",
                    clickable: true,
                },
                navigation: {
                    nextEl: ".introduce-button-next",
                    prevEl: ".introduce-button-prev",
                },
                resizeObserver: true,
                on: {
                    resize: function () {
                        this.updateSize();
                    },
                },
            },
            scrollTop: 0,
        };
    },
    computed: {
        rolelist() {
            return this.rolenavlist.map((v) => (this.isMobile ? "m/" + v : v));
        },
    },
    watch: {
        isMobile() {
            location.reload();
        },
    },
    created() {
        this.setStyle();
        window.addEventListener("resize", this.Debounce(this.setStyle, 1000));
    },
    mounted() {
        this.$refs.app.addEventListener("scroll", (e) => {
            if (e.target.scrollTop > 200) {
                this.$refs.backtop.style.display = "block";
            } else {
                this.$refs.backtop.style.display = "none";
            }
        });
        this.$nextTick(() => {
            const swiper1 = this.$refs.swiper1.$swiper;
            const swiper2 = this.$refs.swiper2.$swiper;
            swiper1.controller.control = swiper2;
            swiper2.controller.control = swiper1;
        });
    },
    methods: {
        setStyle() {
            if (window.innerWidth <= 768) {
                this.isMobile = true;
                this.options1.direction = "horizontal";
                this.options2.direction = "horizontal";
            } else {
                this.isMobile = false;
                this.options1.direction = "vertical";
                this.options2.direction = "vertical";
            }
        },
        Debounce(fn, delay = 500) {
            let timer;
            return function () {
                const args = arguments;
                if (timer) {
                    clearTimeout(timer);
                }
                const context = this;
                timer = setTimeout(() => {
                    timer = null;
                    fn.apply(context, args);
                }, delay);
            };
        },
        goTop() {
            this.$refs.app.scrollTop = 0;
        },
    },
};
</script>
